.header {
  height: 58px;
  display: flex;
  justify-content: space-between;
  width: 100%;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 1px 3px #8f9fac33;
  opacity: 1;
  padding: 0px 16px;
  position: relative;
  z-index: 999;
}

ul.nav {
  width: fit-content;
  display: flex;
  align-items: baseline;
  justify-content: center;
}

ul.nav li {
  padding: 0px 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  list-style: none;
  text-align: center;
  cursor: pointer;
  height: 100%;
  text-transform: uppercase;
  font: normal normal normal 14px/24px Roboto ,sans-serif;
  letter-spacing: 0.28px;
  color: #8f9fac;
  user-select: none;
}

ul.nav li.active {
  border-bottom: 4px solid #0059b2;
  color: #0059b2;
}

.header-logo {
  height: 34px;
  cursor: pointer;
}
