.zoom-buttons {
  position: absolute;
  display: flex;
  flex-direction: column;
  bottom: 8px;
  right: 8px;
}

.map-type-button {
  position: absolute;
  display: flex;
  flex-direction: column;
  top: 8px;
  right: 8px;
}
