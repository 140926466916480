.field-tech-box {
  height: 20px;
  width: 20px;
  border-radius: 50%;
  margin-right: 8px;
  background-color: rgb(52, 116, 254);
}

.cloud-box {
  height: 20px;
  width: 20px;
  border-radius: 50%;
  margin-right: 8px;
  background-color: rgb(255, 148, 69);
}

.sas-box {
  height: 20px;
  width: 20px;
  border-radius: 50%;
  margin-right: 8px;
  background-color: rgb(0, 213, 110);
}

.rma-box {
  height: 20px;
  width: 20px;
  border-radius: 50%;
  margin-right: 8px;
  background-color: rgb(190, 52, 254);
}

.rma-progress-box {
  height: 20px;
  width: 20px;
  border-radius: 50%;
  margin-right: 8px;
  background-color: #ffde01;
}
