.drawing-option-label {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 0.01em;
  text-transform: capitalize;
  font-feature-settings: "pnum" on, "lnum" on;
  color: #0059b2;
  cursor: pointer;
}

.drawing-instructions {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.01em;
  font-feature-settings: "pnum" on, "lnum" on;
  color: #000000;
}

.color-green {
  color: green !important;
}

.instruction-icon {
  color: #0059b2 !important;
  font-size: 16px;
}

.color-red {
  color: red !important;
}

.drawing-option-color-picker {
  border: none;
  outline: none;
  height: 5px;
  width: 24px;
}

.instructions-card {
  height: fit-content !important;
}
