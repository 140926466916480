.search-input {
  background: none !important;
  border: none !important;
  font: normal normal 400 14px/16px Roboto, sans-serif;
  letter-spacing: 0px;
  color: #475057;
  opacity: 1;
}

.search-input:focus {
  outline: none !important;
}

.address-list-row {
  border-bottom: 1px solid #e3e3e3;
  cursor: pointer;
}

.search-result {
  font: normal normal 400 14px/16px Roboto, sans-serif;
  letter-spacing: 0px;
  color: #353435;
  opacity: 1;
}

.search-subresult {
  font: normal normal 400 14px/16px Roboto, sans-serif;
  letter-spacing: 0px;
  color: #8f9fac;
  opacity: 1;
}

.asset-image {
  height: 10px;
  width: 10px;
}

.venue-icon {
  height: 15px;
  width: 15px;
}

ul.search li {
  padding: 0px;
}
