.result-box {
  background: #e5fbef 0% 0% no-repeat padding-box;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
}

.result-label {
  font: normal normal 600 20px/43px Roboto, sans-serif;
  letter-spacing: 0px;
  color: #008947;
  opacity: 1;
}

.result-title {
  font: normal normal 400 14px/18px Roboto, sans-serif;
  letter-spacing: 0px;
  color: #8f9fac;
  opacity: 1;
}
