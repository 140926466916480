.asset-drop-info {
  font: normal normal 300 14px/40px Roboto, sans-serif !important;
  letter-spacing: 0px;
  color: #475057;
  opacity: 1;
  border-bottom: 0.5px solid #d8d8d8;
}

.confirm-azimuth {
  border: 3px solid #0059b2;
  color: #0059b2;
  border-radius: 50%;
  height: 40px;
  width: 40px;
  cursor: pointer;
  background-color: #fff;
}

.reset-azimuth {
  border: 3px solid #f40000;
  color: #f40000;
  border-radius: 50%;
  height: 40px;
  width: 40px;
  cursor: pointer;
  background-color: #fff;
}

.plus-icon {
  background-color: #0059b2;
  color: #fff;
  border-radius: 12px 12px 0px 0px;
  height: 40px;
  width: 60px;
  cursor: pointer;
}

.minus-icon {
  background-color: #0059b2;
  color: #fff;
  border-radius: 0px 0px 12px 12px;
  height: 40px;
  width: 60px;
  cursor: pointer;
}

.azimuth-label {
  height: 40px;
  width: 60px;
  font: normal normal 400 14px/40px Roboto, sans-serif !important;
  letter-spacing: 0px;
  color: #475057;
  background-color: #fff;
  border: 2px solid #0059b2;
}

.sector-icon {
  font-size: 24px;
  color: #eef3f7;
  -webkit-text-stroke-color: #0073ff;
  -webkit-text-stroke-width: 1.5px;
  cursor: default;
}

.active-sector-icon {
  font-size: 25px;
  color: #0073ff;
  -webkit-text-stroke-color: #ffffff;
  -webkit-text-stroke-width: 1.5px;
  text-shadow: 0px 3px 3px #00000029;
  cursor: default;
}

.image-box {
  background: #fff 0% 0% no-repeat padding-box;
  border: 0.5px solid #cccccc;
  border-radius: 12px;
  opacity: 1;
  box-shadow: none;
}

.venue-association-box {
  background: #e5eef7 0% 0% no-repeat padding-box;
  border-radius: 12px;
  opacity: 1;
  box-shadow: none;
}

.association-info {
  font: normal normal 300 12px/20px Roboto, sans-serif !important;
  letter-spacing: 0px;
  color: #475057;
  opacity: 1;
}
