.floor-name {
  font: normal normal 500 20px/24px Roboto, sans-serif;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
}

.floor-title {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  text-transform: uppercase;
  font-feature-settings: "pnum" on, "lnum" on;
  color: #000000;
}

  // .floor-container {
  //   display: flex;
  //   flex-direction: row;
  //   flex-wrap: wrap;
  //   width: 100%;
  //   border-radius: 8px;
  //   border: 0.5px solid #979fa5;
  //   padding: 10px;
  // }
  
  // .add-floor-icon {
  //   color: #0059b2;
  //   font-size: 45px;
  //   cursor: pointer;
  //   /* user-select: none; */
  // }
  
  /* .floor-card {
    display: flex;
    flex-direction: row;
    width: 100%;
    border-radius: 8px;
    cursor: pointer;
    padding: 12px;
    margin-bottom: 12px;
  }
  
  .floor-card:hover {
    background-color: #91c2f31a;
  } */
  
  // .floor-list {
  //   display: flex;
  //   flex-wrap: wrap;
  //   gap: 10px;
  // }
  // .floor-card {
  //   width: 40px;
  //   height: 40px;
  //   opacity: 1;
  //   cursor: pointer;
  //   display: flex;
  //   flex-direction: column;
  //   justify-content: center;
  //   align-items: center;
  //   background: #f5f5f7 0 0 no-repeat padding-box;
  //   border: 1px solid #ccc;
  //   border-radius: 20px;
  //   color: #000;
  // }
  // .floor-card span {
  //   height: 18px;
  // }
  
  // .floor-card:hover {
  //   background-color: #8bc5ff;
  // }
  
  // .floor-card-active {
  //   background-color: #0073ff;
  // }
  
  
  
  // .floor-image-box {
  //   width: 30%;
  //   height: 122px;
  //   border-radius: 8px;
  //   background-color: #f5f5f5;
  //   display: flex;
  //   align-items: center;
  //   justify-content: center;
  //   object-fit: cover;
  // }
  
  // .floor-image-box .image {
  // }
  
  // .floor-image-box .icon {
  //   font-size: 40px;
  //   color: #0059b2;
  // }
  
  // .floor-details {
  //   display: flex;
  //   flex-direction: column;
  // }
  
  // .floor-details .name {
  //   color: #000;
  //   font-size: 14px;
  //   font-family: Roboto, sans-serif;
  //   line-height: 20px;
  //   text-transform: uppercase;
  // }
  
  // .floor-details .icon {
  //   color: #0059b2;
  //   font-size: 20px;
  // }
  
  // .floor-details .description {
  //   color: #475057;
  //   font-size: 12px;
  //   font-family: Roboto, sans-serif;
  //   font-weight: 300;
  //   line-height: 20px;
  //   letter-spacing: 0.12px;
  // }
  
  // .floor-details .total-floors {
  //   color: #475057;
  //   font-size: 12px;
  //   font-family: Roboto, sans-serif;
  //   font-weight: 300;
  //   line-height: 20px;
  //   letter-spacing: 0.12px;
  // }