.venue-name {
  font: normal normal 500 20px/24px Roboto, sans-serif;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
}

.entity-title {
  font: normal normal 400 14px/16px Roboto, sans-serif;
  letter-spacing: 0px;
  color: #9b9b9b;
  opacity: 1;
}

.entity-label {
  font: normal normal 400 14px/16px Roboto, sans-serif;
  letter-spacing: 0px;
  color: #353435;
  opacity: 1;
}

.building-title {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  text-transform: uppercase;
  font-feature-settings: "pnum" on, "lnum" on;
  color: #000000;
}

.add-icon {
  color: #0059b2;
  cursor: pointer;
}

.building-card {
  display: flex;
  flex-direction: row;
  width: 100%;
  border-radius: 8px;
  cursor: pointer;
  padding: 12px;
  margin-bottom: 12px;
}

.building-card:hover {
  background-color: #91c2f31a;
}

.building-image-box {
  width: 30%;
  height: 122px;
  border-radius: 8px;
  background-color: #f5f5f5;
  display: flex;
  align-items: center;
  justify-content: center;
  object-fit: cover;
}

.building-image-box .image {
}

.building-image-box .icon {
  font-size: 40px;
  color: #0059b2;
}

.building-details {
  display: flex;
  flex-direction: column;
}

.building-details .name {
  color: #000;
  font-size: 14px;
  font-family: Roboto, sans-serif;
  line-height: 20px;
  text-transform: uppercase;
}

.building-details .icon {
  color: #0059b2;
  font-size: 20px;
}

.building-details .description {
  color: #475057;
  font-size: 12px;
  font-family: Roboto, sans-serif;
  font-weight: 300;
  line-height: 20px;
  letter-spacing: 0.12px;
}

.building-details .total-floors {
  color: #475057;
  font-size: 12px;
  font-family: Roboto, sans-serif;
  font-weight: 300;
  line-height: 20px;
  letter-spacing: 0.12px;
}

.field-tech-circle {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #3474fe;
}

.cloud-circle {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #ff9445;
}

.sas-circle {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #00d56e;
}
