.sub-heading-vendors {
  font: normal normal 500 13px/22px Roboto ,sans-serif !important;
  letter-spacing: 0.24px;
  color: #6e6e6e;
  text-transform: uppercase;
  opacity: 1;
  margin: 10px 0px;
}

.background-separator {
  background-color: #f3f3f5;
  border-radius: 12px;
  padding: 12px;
}
