.action-box {
  height: 38px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 0.5px solid #cddeec;
  border-radius: 8px;
  opacity: 1;
  cursor: pointer;
}
.action-box:hover {
  box-shadow: 0px 3px 8px #00000029;
  transition: 100ms;
}

.action-label {
  font: normal normal 400 14px/24px Roboto, sans-serif;
  letter-spacing: 0px;
  color: #475057;
  opacity: 1;
}

.h-20 {
  height: 20px;
}
