.img-building-uploaded {
  border-width: 2px;
  border-radius: 12px;
  border-color: #00d56e;
  border-style: dashed;
  background-color: #fff;
  font: normal normal 400 14px/16px Roboto, sans-serif !important;
  letter-spacing: 0px;
  color: #00d56e;
  outline: none;
  transition: border 0.24s ease-in-out;
  cursor: pointer;
  height: 54px;
}

.img-building {
  border-width: 2px;
  border-radius: 12px;
  border-color: #cccccc;
  border-style: dashed;
  font: normal normal 400 14px/16px Roboto, sans-serif !important;
  background-color: #fff;
  color: #9b9b9b;
  outline: none;
  transition: border 0.24s ease-in-out;
  cursor: pointer;
  height: 54px;
}
