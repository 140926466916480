.indoor-details {
  background-color: #e5eef7;
  padding: 10px;
  border-radius: 8px;
}

.indoor-heading {
  font-family: Roboto, sans-serif;
  font-size: 13px;
  font-weight: 500;
  letter-spacing: 0.28px;
  color: #0059b2;
  text-transform: uppercase;
}
.indoor-sub-heading {
  font-family: Roboto, sans-serif;
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0.28px;
  color: #696969;
}
.indoor-value {
  font-family: Roboto, sans-serif;
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0.28px;
  color: #000000;
}

.breaker {
  border-top: 1px solid #aaa;
  margin-bottom: 10px;
  margin-top: 10px;
}
