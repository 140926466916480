.search-box {
  height: 40px;
  background: #8f9fac1a 0% 0% no-repeat padding-box;
  border-radius: 20px;
  opacity: 1;
  cursor: pointer;
}

.search-label {
  font: normal normal 400 14px/16px Roboto ,sans-serif;
  letter-spacing: 0px;
  color: #8f9fac;
  opacity: 1;
}

.position-right-actions {
  right: 1rem;
  top: calc(58px + 1rem);
}

.shifted-position-right-actions {
  right: calc(350px + 1rem);
  top: calc(58px + 1rem);
}

.position-zoom-actions {
  right: 1rem;
  bottom: 1rem;
}

.shifted-position-zoom-actions {
  right: calc(350px + 1rem);
  bottom: 1rem;
}

.infowindow-title {
  font: normal normal 500 12px/22px Roboto ,sans-serif !important;
  letter-spacing: 0.28px;
  color: #ff9445;
  opacity: 1;
}

.infowindow-label {
  font: normal normal 400 14px/22px Roboto ,sans-serif !important;
  letter-spacing: 0.28px;
  color: #475057;
  opacity: 1;
}
