.img-dropzone-set {
  width: 100%;
  width: -moz-available;
  /* WebKit-based browsers will ignore this. */
  width: -webkit-fill-available;
  /* Mozilla-based browsers will ignore this. */
  width: fill-available;
}

.img-dropzone-uploaded {
  flex: 1;
  display: flex;
  flex-direction: column;
  text-align: center;
  border-width: 2px;
  border-radius: 12px;
  border-color: #00d56e;
  border-style: dashed;
  background-color: #fff;
  font: normal normal 400 14px/16px Roboto, sans-serif;
  letter-spacing: 0px;
  color: #00d56e;
  outline: none;
  transition: border 0.24s ease-in-out;
  justify-content: center;
  cursor: pointer;
  height: 54px;
}

.img-dropzone {
  flex: 1;
  display: flex;
  flex-direction: column;
  text-align: center;
  border-width: 2px;
  border-radius: 12px;
  border-color: #cccccc;
  border-style: dashed;
  background-color: #fff;
  font: normal normal 400 14px/16px Roboto, sans-serif;
  letter-spacing: 0px;
  color: #0000008a;
  outline: none;
  transition: border 0.24s ease-in-out;
  justify-content: center;
  cursor: pointer;
  height: 54px;
}

.img-dropzone:focus {
  border-color: #0059b25e;
  color: #0059b2;
  background-color: #e5eef7;
}
