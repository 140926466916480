.secondary-button {
  background: #0059b21a 0% 0% no-repeat padding-box;
  border-radius: 12px;
  opacity: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 48px !important;
  min-height: 48px !important;
  cursor: pointer;
  padding: 0px 24px;
  font: normal normal 400 14px/16px Roboto, sans-serif;
  letter-spacing: 0px;
  color: #0059b2;
}

.secondary-button-disabled {
  background: #ededf0 0% 0% no-repeat padding-box;
  border-radius: 12px;
  opacity: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 48px !important;
  min-height: 48px !important;
  cursor: not-allowed;
  padding: 0px 24px;
  text-align: center;
  font: normal normal 400 14px/16px Roboto, sans-serif;
  letter-spacing: 0px;
  color: #9b9b9b;
}

.secondary-delete-button {
  background: rgba(226, 43, 43, 0.1) 0 0 no-repeat padding-box;
  border-radius: 12px;
  opacity: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 48px !important;
  min-height: 48px !important;
  cursor: pointer;
  padding: 0px 24px;
  font: normal normal 400 14px/16px Roboto, sans-serif;
  letter-spacing: 0px;
  color: rgb(226, 43, 43);
  text-align: center;
}
