.asset-name {
  font: normal normal 500 20px/24px Roboto ,sans-serif;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
}

.asset-description {
  font: normal normal 300 14px/20px Roboto ,sans-serif;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
}

.value-title {
  font: normal normal 400 14px/16px Roboto ,sans-serif;
  letter-spacing: 0px;
  color: #9b9b9b;
  opacity: 1;
}

.value-label {
  font: normal normal 400 14px/16px Roboto ,sans-serif;
  letter-spacing: 0px;
  color: #353435;
  opacity: 1;
}

.active-tab {
  background: #0059b219 0% 0% no-repeat padding-box;
  border-radius: 20px;
  opacity: 1;
  padding: 10px 20px;
  font: normal normal 500 14px/16px Roboto ,sans-serif;
  letter-spacing: 0.28px;
  color: #0059b2;
  text-transform: uppercase;
}

.inactive-tab {
  padding: 10px 20px;
  font: normal normal 500 14px/16px Roboto ,sans-serif;
  letter-spacing: 0.28px;
  color: #8f9fac;
  text-transform: uppercase;
  opacity: 0.65;
}
