.background {
  background: url(https://spectra-pro-v2.s3.amazonaws.com/assets/web-background.webp);
  background-position: left top;
  background-size: cover;
  position: absolute;
  height: 100vh;
  width: 100vw;
}

.login-card {
  display: flex;
  flex-direction: column;
  padding: 24px;
  width: 350px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 8px #00000029;
  border-radius: 12px;
  opacity: 1;
}

.logo {
  height: 114px;
  width: 114px;
}

.action-title {
  font: normal normal 500 22px/24px Roboto ,sans-serif !important;
  letter-spacing: 0px;
  color: #353435;
  opacity: 1;
}

.action-instructions {
  font: normal normal 400 14px/22px Roboto ,sans-serif !important;
  letter-spacing: 0px;
  color: #353435;
  opacity: 1;
}

.interactible-text {
  text-decoration: underline;
  cursor: pointer;
}

.interactible-text:hover {
  color: #0059b2;
}

.forgot-password-text {
  font: normal normal 400 14px/18px Roboto ,sans-serif !important;
  letter-spacing: 0px;
  color: #353435;
  opacity: 1;
}

.agreement-text {
  font: normal normal 400 11px/18px Roboto ,sans-serif !important;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
}

.email-confirmation {
  background-color: #fffaf6;
  border: 1px solid #fa9f47;
  color: #b24603;
  border-radius: 8px;
  width: 100%;
  padding: 12px;
  font: normal normal 400 14px/22px Roboto ,sans-serif !important;
  letter-spacing: 0.2px;
}
