.dropdown button {
  /* appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none; */
  text-align: start;
  background-repeat: no-repeat;
  background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3e%3cpolyline points='6 9 12 15 18 9'%3e%3c/polyline%3e%3c/svg%3e");
  background-position: right 12px center;
  background-size: 12px;
  background-color: #fff;
  opacity: 1;
  height: 48px;
  padding: 0px 12px;
  border-radius: 12px;
  -webkit-border-radius: 12px;
  -moz-border-radius: 12px;
  border: 0.5px solid #cccccc;
  font: normal normal 400 14px/16px Roboto, sans-serif;
  letter-spacing: 0px;
  color: #000000;
}

.dropdown button:focus {
  outline: 0.5px solid #0059b2 !important;
}

.dropdown-menu span {
  font: normal normal 400 14px/20px Roboto, sans-serif;
  color: #000000;
}

.dropdown-menu {
  --bs-dropdown-border-radius: 12px;
  box-shadow: 0px 0px 8px 1px #0000003b;
  /* --bs-dropdown-bg: #0059b2;
  --bs-dropdown-color: white; */
  --bs-dropdown-border-color: #0059b2;
}
