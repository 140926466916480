.accordion-customs {
  --bs-accordion-btn-focus-box-shadow: none;
  --bs-accordion-btn-focus-border-color: #8f9fac;
  --bs-accordion-border-color: #dee2e6;
  --bs-accordion-border-width: 0.5px;
  --bs-accordion-border-radius: 10px;
  --bs-accordion-inner-border-radius: 10px;
  --bs-accordion-active-color: #353435;
  --bs-accordion-active-bg: #eef3f7;
  --bs-accordion-body-padding-x: 0px;
  --bs-accordion-body-padding-y: 0px;
}

.accordion-headline {
  font: normal normal 500 14px/16px Roboto, sans-serif;
  letter-spacing: 0px;
  color: #353435;
  opacity: 1;
}

.submit-parameter-info {
  font: normal normal 400 14px/16px Roboto, sans-serif;
  letter-spacing: 0px;
  color: #9b9b9b;
  opacity: 1;
}

.status-circle-rma-requested {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: #be34fe;
  margin-right: 8px;
}

.status-circle-rma-in-progress {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: #ffde01;
  margin-right: 8px;
}

.status-circle-sas {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: #00d56e;
  margin-right: 8px;
}

.status-circle-cloud {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: #ff9445;
  margin-right: 8px;
}

.status-circle-field-tech {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: #3474fe;
  margin-right: 8px;
}
